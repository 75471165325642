var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "carousel" },
    [
      _c("div", { staticClass: "title" }, [
        _c("h1", { staticClass: "white--text" }, [
          _vm._v(
            " " + _vm._s(_vm.DCSLanguageService.translate("NewsTitle")) + " "
          )
        ])
      ]),
      _vm.featuredNewsArticle
        ? _c("div", { staticClass: "text-media-container" }, [
            _c("div", { staticClass: "text-container" }, [
              _c("div", { staticClass: "title-container" }, [
                _c("h2", [
                  _vm._v(" " + _vm._s(_vm.featuredNewsArticle.title) + " ")
                ]),
                _vm.featuredNewsArticle.externalArticleLink
                  ? _c(
                      "div",
                      [
                        _c("ShareButtonComponent", {
                          attrs: {
                            "share-link":
                              _vm.featuredNewsArticle.externalArticleLink,
                            color: "white"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _c("hr"),
              _c("div", { staticClass: "text-description" }, [
                !_vm.isExpanded
                  ? _c(
                      "span",
                      [
                        _c("vue-markdown", {
                          staticClass: "show-less",
                          attrs: { source: _vm.featuredNewsArticle.description }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.featuredNewsArticle.description.length > 1500
                  ? _c("div", [
                      _c("i", [
                        !_vm.isExpanded
                          ? _c(
                              "a",
                              {
                                staticClass: "read-more",
                                on: {
                                  click: function($event) {
                                    return _vm.expandArticle(true)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.DCSLanguageService.translate("ReadOn")
                                    ) +
                                    "... "
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    ])
                  : _vm._e(),
                _vm.isExpanded
                  ? _c(
                      "span",
                      [
                        _c("vue-markdown", {
                          staticClass: "show-more",
                          attrs: { source: _vm.featuredNewsArticle.description }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _c("i", [
                _vm.isExpanded
                  ? _c(
                      "a",
                      {
                        staticClass: "read-more",
                        on: {
                          click: function($event) {
                            return _vm.expandArticle(false)
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.DCSLanguageService.translate("ReadLess")
                            ) +
                            "... "
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ]),
            _c(
              "div",
              { staticClass: "media-container" },
              [
                _c("MediaCarouselComponent", {
                  attrs: { media: _vm.featuredNewsArticle.media }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "slide-group-container" },
        [
          _vm.newsArticles.length
            ? _c("SlideGroupComponent", {
                attrs: { "carousel-items": _vm.newsArticles },
                on: { "item-select": _vm.newsSelected }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.hasNoNews
        ? _c("div", { staticClass: "display-image" }, [
            _c("img", {
              staticClass: "display-image",
              attrs: { src: require("@/assets/no_webinars.svg") }
            }),
            _c("h2", [
              _vm._v(
                " " + _vm._s(_vm.DCSLanguageService.translate("NoNews")) + " "
              )
            ])
          ])
        : _vm._e(),
      _vm.isLoading ? _c("CarouselPageSkeletonLoader") : _vm._e(),
      _c("CityDarkBGComponent", {
        attrs: { "modifier-class": "dark-bg--fixed" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }