


























































































import { Vue, Component } from "vue-property-decorator";
import { ComponentOptions } from "vue/types/options";

import { take } from "rxjs/operators";

import CityDarkBGComponent from "@/components/core/backgrounds/CityDarkBG.vue";
import MediaCarouselComponent from "@/components/media/carousel/MediaCarousel.vue";
import SlideGroupComponent from "@/components/media/slide-group/SlideGroup.vue";
import CarouselPageSkeletonLoader from "@/components/media/carousel/CarouselPageSkeletonLoader.vue";

import { DCSLanguageService, NewsArticleService, MetaService } from "@/services";
import { NewsArticle } from "@/types/news-article.interface";

import VueMarkdown from "vue-markdown";
import ShareButtonComponent from "@/components/products/buttons/ShareButton.vue";

const metaInfo: ComponentOptions<Vue>["metaInfo"] = {
  title: "What's new in Dow Building Science?",
  meta: [
    {
      vmid: "description",
      name: "description",
      content:
        "Catch up on the latest announcements from Dow Building Science.",
    },
  ],
};

export const metaInfoMixin = {
  metaInfo,
};

@Component({
  mixins: [metaInfoMixin],
  components: {
    CarouselPageSkeletonLoader,
    CityDarkBGComponent,
    MediaCarouselComponent,
    SlideGroupComponent,
    VueMarkdown,
    ShareButtonComponent
  },
})
export default class NewsArticles extends Vue {
  public DCSLanguageService = new DCSLanguageService();

  private newsService = new NewsArticleService();
  private metaService = new MetaService();

  featuredNewsArticle: NewsArticle | null | undefined = null;
  newsArticles: NewsArticle[] = [];

  hasNoNews = false;
  isLoading = true;
  isExpanded = false;

  newsSelected(index: number): void {
    this.isExpanded = false;
    this.featuredNewsArticle = this.newsArticles[index];
  }

  expandArticle(readMore: boolean): void {
    if (readMore) {
      this.isExpanded = true;
    } else {
      this.isExpanded = false;
    }
  }

  private created(): void {
    this.metaService.updateMetaDescription("Catch up on the latest announcements from Dow Building Science.");
    this.metaService.updateMetaTitle("What's new in Dow Building Science?");

    this.newsService
      .getNewsArticles()
      .pipe(take(1))
      .subscribe((news: NewsArticle[]) => {
        this.newsArticles = news.sort((a, b) =>
          (a.isFeatured === b.isFeatured)? 0 : a.isFeatured? -1 : 1
        );
        this.featuredNewsArticle = news[0] ?? null;
        if (this.newsArticles.length == 0) 
          this.hasNoNews = true;
        this.isLoading = false;
      });
  }
}
